import { Flex } from '@iheartradio/web.accomplice/flex';
import { Text } from '@iheartradio/web.accomplice/text';
import {
  Button,
  ErrorFilledIcon,
  Notification,
  SearchIcon,
  useToast,
} from '@iheartradio/web.companion';
import { isBlank } from '@iheartradio/web.utilities';
import {
  type ClientLoaderFunction,
  Link as RouterLink,
  useLoaderData,
  useRevalidator,
  useSearchParams,
} from '@remix-run/react';
import { lazy, memo, useEffect, useRef } from 'react';
import { $path } from 'remix-routes';

import { InlineAd } from '~app/ads/display/inline-ad';
import { usePageView } from '~app/analytics/use-page-view';
import { Deferred } from '~app/components/deferred';
import { useConfig } from '~app/contexts/config';
import { playback } from '~app/playback/playback';
import type { HomeClientLoaderData } from '~app/routes/api.v1.home';
import {
  runUserEducationTour,
  TOUR_STORAGE_KEY,
  TOUR_VERSION,
} from '~app/user-education-tour';
import {
  fetchClientData,
  fetchWithTimeout,
} from '~app/utilities/fetch-client-data';

import type { HomeLoader, HomeLoaderData } from './.server/loader';
import { HomeHero } from './components/_home-hero';
import { DownloadTheAppRow } from './components/download-the-app';
import { LOADER_MESSAGES } from './constants';

export { loader } from './.server/loader';
export { meta } from './meta';
export { headers } from '~app/defaults.server';

const RecentlyPlayedCarousel = lazy(
  () => import('./components/recently-played-carousel'),
);
const LocalLiveStationsCarousel = lazy(
  () => import('./components/local-live-stations-carousel'),
);
const RecommendedLiveStationsCarousel = lazy(
  () => import('./components/recommended-live-stations-carousel'),
);
const RecommendedPodcastsCarousel = lazy(
  () => import('./components/recommended-podcasts-carousel'),
);
const RecommendedArtistsCarousel = lazy(
  () => import('./components/recommended-artists-carousel'),
);
const FeaturedPodcastsCarousel = lazy(
  () => import('./components/featured-podcasts-carousel'),
);
const MadeForYou = lazy(() => import('./components/made-for-you'));
const RecommendedPlaylistsCarousel = lazy(
  () => import('./components/recommended-playlists-carousel'),
);
const FeaturedPlaylistsCarousel = lazy(
  () => import('./components/featured-playlists-carousel'),
);
const PlaylistsByGenreCarousel = lazy(
  () => import('./components/playlists-by-genre-carousel'),
);
const PopularNewsCarousel = lazy(
  () => import('./components/popular-news-carousel'),
);

export const clientLoader: ClientLoaderFunction = async ({
  request,
  serverLoader,
}) => {
  // Build the url for client loader data request from the url passed to this loader
  const { origin, searchParams } = new URL(request.url);
  const clientDataRequestUrl = new URL(`${origin}${$path('/api/v1/home')}`);
  // Pass along any search params (genre, market)
  for (const [key, value] of searchParams.entries()) {
    clientDataRequestUrl.searchParams.set(key, value);
  }

  const serverData = await serverLoader<HomeLoaderData>();
  const clientData = await fetchClientData<HomeClientLoaderData>(
    fetchWithTimeout(clientDataRequestUrl),
    {
      recentlyPlayedStations: [],
      popularNewsItems: [],
    },
  );

  return {
    ...serverData,
    ...clientData,
  };
};
clientLoader.hydrate = true;

export default memo(function Home() {
  const {
    pageName,
    featuredPlaylistsData,
    featuredPodcastsData,
    internalCampaign,
    isAnonymousUser,
    recentlyPlayedandLiveStations,
    madeForYouData,
    playlistsByGenre,
    playlistRecs,
    popularNewsItems,
    recommendedArtists,
    recommendedPodcasts,
    selectedMarketId,
    trendingData,
  } = useLoaderData<HomeLoader>();
  // ---------------
  // While on the homepage, we should update the "Recently Played" section whenever a user
  // plays content from this view

  // Get a reference to the player and revalidator
  const player = playback.usePlayer();
  const revalidator = useRevalidator();

  // In `useEffect`, subscribe to `player.play` and revalidate on any play action
  // `return player.subscribe` will call the unsubscribe function when this component
  // unmounts
  useEffect(() => {
    // Only update "Recently Played" if the user is not anonymous
    // If user is anonymous, the "Recently Played" is not even shown
    if (!isAnonymousUser) {
      return player.subscribe({
        play() {
          revalidator.revalidate();
        },
      });
    }
  }, [player, revalidator, isAnonymousUser]);
  // ---------------

  const [searchParams, setSearchParams] = useSearchParams();
  const toast = useToast();
  const config = useConfig();

  // Run the user education tour if the user has not completed or dismissed it
  useEffect(() => {
    const tourVersion = localStorage.getItem(TOUR_STORAGE_KEY);
    if (
      tourVersion !== TOUR_VERSION.toString() &&
      config?.features?.showUserEducationTour
    ) {
      runUserEducationTour({
        onComplete: () => {
          localStorage.setItem(TOUR_STORAGE_KEY, TOUR_VERSION.toString());
        },
      });
    }
  }, []);

  // If root loader detected an invalid session, the user will be redirected to home with a
  // query param of `?message=badsession`. This effect looks for that message key and displays
  // a toast notification notifying the user they need to log in again
  useEffect(() => {
    if (searchParams.get('message') === LOADER_MESSAGES.BAD_SESSION) {
      toast(
        dismiss => (
          <Notification
            footer={
              <Button
                as={RouterLink}
                color={{ dark: 'white', light: 'gray' }}
                css={{ textDecoration: 'none' }}
                kind="primary"
                size="small"
                to={`/login/auth?redirectUrl=${window.location.href}`}
              >
                Log In
              </Button>
            }
            icon={<ErrorFilledIcon fill="red-650" />}
            kind="error"
            onDismiss={dismiss}
            title="Session Expired"
          >
            Please log in again
          </Notification>
        ),
        {
          duration: 10_000,
        },
      );
      setSearchParams(previous => {
        previous.delete('message');
        return previous;
      });
    }
  }, [searchParams, setSearchParams, toast, config.urls.account]);

  usePageView(pageName);

  const sectionPosition = useRef<number>(0);

  console.log('trendingData:', trendingData);

  return (
    <Flex direction="column" gap="$32" width="100%">
      <HomeHero campaignData={internalCampaign} />

      <Deferred resolve={recentlyPlayedandLiveStations}>
        {function RecentlyPlayedAndLiveStations([
          recentlyPlayedStations,
          liveStations,
        ]) {
          const adPosition = useRef<string | undefined>(undefined);
          let { current: recentlyPlayedPosition } = sectionPosition;
          if (recentlyPlayedStations.length > 0) {
            adPosition.current = 'RecentlyPlayed';
            recentlyPlayedPosition = recentlyPlayedPosition + 1;
          }
          const { current: liveStationsPosition } = sectionPosition;
          if (liveStations.length > 0 && adPosition.current === undefined) {
            adPosition.current = 'LiveStations';
          }
          return (
            <>
              {recentlyPlayedStations.length > 0 ?
                <>
                  <RecentlyPlayedCarousel
                    pageName={pageName}
                    sectionPosition={recentlyPlayedPosition}
                    stations={recentlyPlayedStations}
                  />
                  {adPosition.current === 'RecentlyPlayed' ?
                    <InlineAd />
                  : null}
                </>
              : null}
              {liveStations.length > 0 ?
                <>
                  {isAnonymousUser ?
                    <LocalLiveStationsCarousel
                      key={`market-${selectedMarketId}`}
                      liveStations={liveStations}
                      pageName={pageName}
                      sectionPosition={liveStationsPosition}
                    />
                  : <RecommendedLiveStationsCarousel
                      liveStations={liveStations}
                      pageName={pageName}
                      sectionPosition={liveStationsPosition}
                    />
                  }
                  {adPosition.current === 'LiveStations' ?
                    <InlineAd />
                  : null}
                </>
              : null}
            </>
          );
        }}
      </Deferred>
      <Deferred resolve={recommendedPodcasts}>
        {function RecommendedPodcasts(recommendedPodcasts) {
          if (isBlank(recommendedPodcasts)) {
            return null;
          } else {
            sectionPosition.current = sectionPosition.current + 1;
            return (
              <RecommendedPodcastsCarousel
                pageName={pageName}
                podcasts={recommendedPodcasts}
                sectionPosition={sectionPosition.current}
                title={
                  isAnonymousUser ?
                    "Today's Top Podcasts"
                  : 'Recommended Podcasts'
                }
              />
            );
          }
        }}
      </Deferred>
      <Deferred resolve={recommendedArtists}>
        {function RecommendedArtists(recommendedArtists) {
          if (isBlank(recommendedArtists)) {
            return null;
          } else {
            sectionPosition.current = sectionPosition.current + 1;
            return (
              <RecommendedArtistsCarousel
                artists={recommendedArtists}
                pageName={pageName}
                sectionPosition={sectionPosition.current}
                sectionTitle={
                  isAnonymousUser ? 'Popular Artists' : 'Artists You Might Like'
                }
              />
            );
          }
        }}
      </Deferred>
      <Deferred resolve={featuredPodcastsData}>
        {function FeaturedPodcasts({ featuredPodcasts }) {
          if (isBlank(featuredPodcasts)) {
            return null;
          } else {
            sectionPosition.current = sectionPosition.current + 1;
            return (
              <FeaturedPodcastsCarousel
                pageName={pageName}
                podcasts={featuredPodcasts}
                sectionPosition={sectionPosition.current}
              />
            );
          }
        }}
      </Deferred>
      <Deferred resolve={madeForYouData}>
        {function MadeForYouRecs({ recs }) {
          sectionPosition.current = sectionPosition.current + 1;
          return (
            <MadeForYou
              pageName={pageName}
              playlists={recs}
              sectionPosition={sectionPosition.current} // We always show this section even if there are no madeForYouRecs
            />
          );
        }}
      </Deferred>
      <Deferred resolve={playlistRecs}>
        {function PlaylistRecs(playlistRecs) {
          if (isBlank(playlistRecs)) {
            return null;
          } else {
            sectionPosition.current = sectionPosition.current + 1;
            return (
              <RecommendedPlaylistsCarousel
                pageName={pageName}
                playlists={playlistRecs}
                sectionPosition={sectionPosition.current}
              />
            );
          }
        }}
      </Deferred>
      <Deferred resolve={featuredPlaylistsData}>
        {function FeaturedPlaylists({ featuredPlaylists }) {
          if (isBlank(featuredPlaylists)) {
            return null;
          } else {
            sectionPosition.current = sectionPosition.current + 1;
            return (
              <FeaturedPlaylistsCarousel
                pageName={pageName}
                playlists={featuredPlaylists}
                sectionPosition={sectionPosition.current}
              />
            );
          }
        }}
      </Deferred>
      <Deferred resolve={playlistsByGenre}>
        {function PlaylistsByGenre({
          playlistGenres,
          playlists,
          selectedPlaylistGenre,
        }) {
          if (isBlank(playlists)) {
            return null;
          } else {
            sectionPosition.current = sectionPosition.current + 1;
            return (
              <PlaylistsByGenreCarousel
                key={selectedPlaylistGenre}
                pageName={pageName}
                playlistGenres={playlistGenres}
                playlistsByGenre={playlists}
                sectionPosition={sectionPosition.current}
                selectedPlaylistGenre={selectedPlaylistGenre}
              />
            );
          }
        }}
      </Deferred>
      <Deferred resolve={popularNewsItems}>
        {function PopularNews(popularNewsItems) {
          if (isBlank(popularNewsItems)) {
            return null;
          } else {
            sectionPosition.current = sectionPosition.current + 1;
            return (
              <PopularNewsCarousel
                pageName={pageName}
                popularNewsItems={popularNewsItems}
                sectionPosition={sectionPosition.current}
              />
            );
          }
        }}
      </Deferred>
      <Flex direction="row" justifyContent="center" padding="0 $16">
        <Flex alignItems="center" gap="$12" justifyContent="space-around">
          <Text kind="subtitle-2">Looking for something specific?</Text>
          <Button
            as={RouterLink}
            color={{ dark: 'white', light: 'gray' }}
            kind="secondary"
            size="small"
            to="/search"
          >
            <SearchIcon size={18} />
            Search
          </Button>
        </Flex>
      </Flex>
      <DownloadTheAppRow />
    </Flex>
  );
});
